// login
export const loginSuccess = "Login successful!";
export const loginError = "Login failed";

// logOut
export const tokenExpiryLogOutMsg = "Session expired, please login again";
export const logOutSuccess = "Signed out successfully!";
export const logOutMsg = "You have been signed out. Please login in again";

// forgot/ reset password
export const forgotPasswordError = "Reset password request failed";
export const setNewPasswordError = "Reset password failed";
export const resendResetLinkSuccess = "Reset link sent to your email!";

// sign-up
export const signUpError = "Sign up failed!";
export const resendRegisterTokenSucess =
  "Verification link sent to your email!";
export const signUpVerificationError = "Email verification failed";

// resend-otp
export const resendOtpSuccess = "New OTP sent to your email!";
export const resendOtpError = "Resend OTP failed";

// wallet
export const generateAddressError = "Address generation failed";
export const minWithdrawError = "Couldn't fetch min withdraw";
export const maxWithdrawError = "Couldn't fetch max withdraw";
export const networkFeeError = "Couldn't fetch network fee";
// Add or withdraw funds
export const fiatUpcomingInfo = "Fiat option coming to your country soon...";
export const withdrawFiatUpcomingInfo = "Fiat option will be added soon...";
export const withdrawalInitiated = "Withdrawal initiated!";
export const withdrawalErrMsg = "Withdrawal failed";
export const btcError = "BTC not found in coins";
export const polygonError = "No supported coin available for Polygon";

// product
export const investSuccess = "Investment successfull!";
export const redeemSuccess = "Redemption successfull!";
export const investAmountError = "Invest amount invalid";
export const investAmountGtBalance = "amount is greater than available balance";
export const investAmountLtMinInvestAmount =
  "amount less than min investment amount";
export const redeemAmountError = "Redeem amount invalid";
export const redeemAmountGtCurrInvestAmount =
  "amount is greater than current value of invested amount";
export const redeemAmountLteZero = "amount is less than or equal to zero";
export const redemptionError = "Redemption failed";
export const investError = "Investment failed";
export const cancelPendingOrderSuccess = "Pending order cancelled!";
export const canelPendingOrderError = "Pending order cancellation failed!";

// sip
export const sipCreateSuccess = "SIP created successfully!";
export const sipCreateError = "SIP creation failed";
export const pauseSipSuccess = "SIP paused successfully!";
export const pauseSipError = "Could not pause SIP";
export const resumeSipSuccess = "SIP resumed successfully!";
export const resumeSipError = "Could not resume SIP";
export const stopSipSuccess = "SIP stopped successfully!";
export const stopSipError = "Could not stop SIP";
export const sipModifySuccess = "SIP modified successfully!";
export const sipModifyError = "Could not modify SIP";

// dca
export const startDcaSuccess = "Started DCA successfully!";
export const startDcaError = "Failed to start DCA";
export const modifyDcaSuccess = "Modified DCA successfully!";
export const modifyDcaError = "Failed to modifpy DCA";
export const pauseDcaSuccess = "DCA paused successfully!";
export const pauseDcaError = "Could not pause DCA";
export const resumeDcaSuccess = "DCA resumed successfully!";
export const resumeDcaError = "Could not resume DCA";
export const stopDcaSuccess = "DCA stopped successfully!";
export const stopDcaError = "Could not stop DCA";
export const cancelPendingDcaOrderSuccess = "Pending DCA order cancelled!";
export const canelPendingDcaOrderError =
  "Pending DCA order cancellation failed";

// settings
export const changePasswordError = "Change password failed";
export const changePasswordSuccess =
  "Password changed successfully, please login again";
export const profileChangeSuccess = "User details updated!";
export const profileChangesError = "Error while updating user details";

// MFA setup messages
export const mfaVerifySetupSuccess =
  "Authenticator verification successful, please login again.";
export const mfaVerifySetupError = "Authenticator verification failed";
export const mfaDisableSuccess =
  "Authenticator disabled successfully, please login again";
export const mfaDisableError = "Disabling authenticator failed!";
export const mfaEnableSuccess = "Authenticator secret generated!";
export const mfaEnableError = "Enabling authenticator failed!";

// dashboard
export const kycStatusError = "KYC status could not be fetched!";
export const actionNotAllowedPreKyc = "Please complete your KYC first!";
export const investSIPMessage = "Coming soon, stay tuned!";
export const actionNotAllowedPreKycAndDeposit =
  "Please complete KYC and make a deposit first!";

// supported assets
export const supportedAssetsError =
  "Failed to fetch supported tokens and chain!";

// referral
export const redeemRewardPtsSuccess = "Reward points redemption succesful!";
export const redeemRewardPtsError = "Reward points redemptionn failed!";

// onboarding tour
export const updateOnboardingStatusError = "Could not update onboarding status";
